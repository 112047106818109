<template>
  <section class="section-narrow">
    <div class="container">
      <PrefilledLoginWarning v-if="prefilled" />
      <div class="alert alert-info" role="alert">
        <i class="fa-sharp fa-light fa-circle-info me-2"></i>
        If you are signing on behalf of a {{ admitteeNomenclature }}, <router-link to="/signer/login" class="alert-link">click here</router-link>.
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ admitteeNomenclatureTitle }}</h5>
          <h6 class="card-subtitle mb-2 text-body-secondary">Log in as a {{ admitteeNomenclature }}.</h6>

          <hr>

          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="first-name"
                  v-model="firstname"
                  :disabled="prefilled"
                  placeholder="First Name"
                >
                <label for="first-name" class="form-label">First Name</label>
              </div>
            </div>
            <div class="col">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="last-name"
                  v-model="lastname"
                  :disabled="prefilled"
                  placeholder="Last Name"
                >
                <label for="last-name" class="form-label">Last Name</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <input
                  type="date"
                  class="form-control"
                  id="date-of-birth"
                  v-model="dob"
                  :disabled="prefilled"
                  placeholder="Date of Birth"
                >
                <label for="date-of-birth" class="form-label">Date of Birth</label>
              </div>
            </div>
            <div class="col">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="zip"
                  v-model="zipcode"
                  maxlength="5"
                  :disabled="prefilled"
                  placeholder="Zip Code"
                >
                <label for="zip" class="form-label">Zip Code</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-floating">
                <input
                  type="password"
                  class="form-control"
                  id="ssn"
                  v-model="last4ssn"
                  maxlength="4"
                  placeholder="Last 4 of SSN"
                >
                <label for="ssn" class="form-label">Last 4 of SSN</label>
              </div>
            </div>
          </div>

          <hr>

          <p class="lh-sm text-body-tertiary"><small>
            By using eAdmit, you are agreeing to receive certain information and notices electronically and to sign contracts and other records electronically. For more information on electronic signatures and records, please review our Electronic Signature Terms & Conditions.
          </small></p>

          <button type="submit" class="btn btn-primary float-end" @click="login">
            Next
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Cookies from "js-cookie";
import endpoints from "../../mixins/app/endpoints";
import PrefilledLoginWarning from "./components/PrefilledLoginWarning.vue";
import axios from '@/axios'

export default {
    components: {
        PrefilledLoginWarning
    },
    mixins: [
        endpoints
    ],
    data () {
        return {
            firstname: null,
            lastname: null,
            dob: null,
            zipcode: null,
            last4ssn: null,
            prefilled: false,
        }
    },
    mounted () {
        if (this.$store.getters['store/isAuthenticated']) {
            this.$router.push('/dashboard');
        }

        if (this.$route.query.firstname) {
            this.prefilled = true;
            this.firstname = this.$route.query.firstname;
        }

        if (this.$route.query.lastname) {
            this.lastname = this.$route.query.lastname;
        }

        if (this.$route.query.dob) {
            this.dob = this.$route.query.dob;
        }

        if (this.$route.query.zipcode) {
            this.zipcode = this.$route.query.zipcode;
        }
    },
    methods: {
        login: function () {
            this.$store.dispatch('store/clearErrors');
            axios.post(this.endpoints.patientLogin, {
                username: {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    dob: this.dob,
                    zipCode: this.zipcode,
                },
                last4ssn: this.last4ssn
            }).then(response => {
                Cookies.remove('auth_token');
                Cookies.remove('patient');
                Cookies.remove('signer');

                this.$store.dispatch('store/setPatient', response.data.user);

                window.currentFacility = response.data.currentFacility;
                window.facilityDocuments = response.data.facilityDocuments;
                window.importantDocuments = response.data.importantDocuments;

                Cookies.set('auth_token', response.data.token, { secure: true });
                this.$router.push('/dashboard');
            }).catch(error => {
                this.$store.dispatch('store/setErrors', [ error.response.data.message ]);
            });
        }
    },
    computed: {
        admitteeNomenclatureTitle () {
            return window.admitteeNomenclatureTitle
        },
        admitteeNomenclature () {
            return window.admitteeNomenclature
        }
    }
}
</script>

<style lang="scss" scoped></style>

