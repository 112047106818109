<template>
  <section class="section-narrow">
    <div class="container">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Welcome</h5>
          <p class="card-text text-body-secondary">Please log in to continue.</p>
          <hr>
          <form @submit.prevent="login">

            <div class="form-floating mb-3">
              <input v-model="state.email" type="email" class="form-control" id="email" placeholder="Email">
              <label for="email" class="form-label">Email</label>
            </div>


            <div class="form-floating mb-3">
              <input v-model="state.password" type="password" class="form-control" id="password" placeholder="Password">
              <label for="password" class="form-label">Password</label>
            </div>

            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <a href="/forgot-password" class="link-secondary">Forgot your password?</a>
              <button type="submit" class="btn btn-primary float-end" :disabled="loading">
                <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Log in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Cookies from 'js-cookie'
import axios from '@/axios'
import { useEndpoints } from '@/composables/useEndpoints'
import { onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const { endpoints } = useEndpoints()

const state = reactive({
  email: null,
  password: null
})

onMounted(() => {
  if (store.state.isAuthenticated) {
    router.push('/dashboard');
  }
})

const loading = ref(false);

const login = () => {
  loading.value = true;
  store.dispatch('store/clearErrors');
  axios.post(endpoints.value.login, state)
    .then(response => {
      Cookies.remove('patient')
      store.dispatch('store/clearPatient');
      store.dispatch('store/setUser', response.data);
      window.location.href = endpoints.value.admin;
    }).catch(error => {
      store.dispatch('store/setErrors', [ error.response.data.message ]);
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
