<template>
    <tr>
        <td>
            <span>{{ document.packet.name }}</span>
        </td>
        <td class="text-center">
            <span>{{ document.status }}</span>
        </td>
        <td class="text-center">
            <template v-if="document.status === 'Completed'">
                <button class="btn btn-sm btn-outline-success" @click="download(document)" :disabled="isDownloading">
                    <template v-if="isDownloading">
                        <span><i class="fa-regular fa-loader fa-spin"></i></span>
                    </template>
                    <template v-else>
                        Download
                    </template>
                </button>
            </template>
            <template v-else>
                <template v-if="matchingSignature">
                    <template v-if="isSignatureComplete(matchingSignature)">
                        Complete
                    </template>
                    <template v-else-if="packetHasSignerOrder(document.packet)">
                        <template v-if="document.nextSignature.id === matchingSignature?.id">
                            <a href="#" class="btn btn-sm btn-primary" @click="sign(matchingSignature)">
                                Sign
                            </a>
                        </template>
                        <template v-else>
                            Awaiting Other Signatures
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="isSignatureIncomplete(matchingSignature)">
                            <button class="btn btn-sm btn-primary" @click="sign(matchingSignature)">
                                Sign
                            </button>
                        </template>
                    </template>
                </template>
                <template v-else>
                    No Signature Needed
                </template>
            </template>
        </td>
    </tr>
</template>

<script>
import FileSaver from 'file-saver';
import authenticatedClient from "../../mixins/app/authenticatedClient";
import config from "../../mixins/app/config";
import helpers from "../../mixins/helpers";

export default {
    props: ['document'],
    mixins: [
        authenticatedClient,
        config,
        helpers
    ],
    computed: {
        patient () {
            return this.$store.getters['store/patient'];
        },
        signer () {
            return this.$store.getters['store/signer'];
        },
        isSigner () {
            return !_.isEmpty(this.$store.getters['store/signer']);
        },
        matchingSignature () {
            return _.find(this.document.signatures, (signature) => {
                if (this.isSigner) {
                    return signature.signable_type === 'App\\Models\\Signer'
                        && signature.signable_id === this.signer.id;
                } else {
                    return signature.signable_type === 'App\\Models\\Patient'
                        && signature.signable_id === this.patient.id;
                }
            });
        },
        readyToSign () {
            if (this.packetHasSignerOrder(this.document.packet)) {
                console.log(this.document.nextSignature);
            } else {
                // console.log(this.matchingSignature);
            }
        }
    },
    data () {
        return {
            isDownloading: false
        };
    },
    methods: {
        checkNextSigner (nextSignature) {
            if (!_.isNull(this.$store.getters['store/signer'])) {
                return this.isNextSigner(nextSignature, this.signer.id, 'Patient');
            } else if (!_.isNull(this.$store.getters['store/patient'])) {
                return this.isNextSigner(nextSignature, this.patient.id, 'Patient');
            }
            return false;
        },
        download (document) {
            this.isDownloading = true;

            this.client.get(
                '/api/v1/patients/' + this.patient.id + '/document/' + document.id,
                {responseType: 'blob'}
            ).then(response => {
                FileSaver.saveAs(response.data, this.getFileName(document));
            }).catch(error => {
                // Do Nothing
            }).finally(() => {
                this.isDownloading = false;
            });
        },
        getFileName(document) {
            return _.kebabCase(
                this.patient.firstname + ' ' + this.patient.lastname + ' ' + document.packet.name
            ) + '.pdf';
        },
        sign (signature) {
            this.$router.push({ name: 'Sign', params: { id: signature.id, signatureId: signature.signature_id } });
        }
    }
}
</script>
