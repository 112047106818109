<template>
    <section class="content">
        <div class="container">
            <router-link to="/">
                <img :src="appLogo" :alt="this.config.name" class="home-logo"/>
            </router-link>

            <div class="btn-wrap">
                <router-link to="/patient/login" class="btn btn-lg btn-primary">{{ admitteeNomenclature }}</router-link>
                <router-link to="/signer/login" class="btn btn-lg btn-primary">Responsible Party</router-link>
                <a href="/admin" class="btn btn-lg btn-primary">Facility Rep</a>
            </div>
        </div>
    </section>
</template>

<script>
import config from "../../mixins/app/config";
import endpoints from "../../mixins/app/endpoints";

export default {
    mixins: [
        config,
        endpoints
    ],
    mounted () {
        if (this.$store.getters['store/isAuthenticated']) {
            this.$router.push('/dashboard');
        }
    },
    computed: {
        admitteeNomenclature () {
            return window.admitteeNomenclatureTitle
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    margin-top: 80px;
}
.btn-wrap {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.btn {
    width: 100%;
    display: block;
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
}
.home-logo {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    display: block;
    margin-right: auto;
    margin-bottom: 40px;
}
</style>

