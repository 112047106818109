<template>
  <section class="sign mx-auto">
    <div v-if="loading" class="hellosign-loading">
      <i class="fa-regular fa-loader fa-spin fa-6x"></i>
    </div>
    <div v-else-if="error" class="d-flex h-full align-items-center">
      <generic-modal title="Error" :body="error" buttonActionLabel="Back" :buttonAction="$router.back" />
    </div>
    <div id="hellosign-container" v-show="!loading"></div>
  </section>
</template>

<script>
import HelloSign from 'hellosign-embedded';
import Cookies from "js-cookie";
import authenticatedClient from "../../mixins/app/authenticatedClient";
import GenericModal from '../../components/app/GenericModal.vue';
import axios from '@/axios'

export default {
  components: {
    GenericModal
  },
  mixins: [
    authenticatedClient
  ],
  computed: {
    id () {
      return this.$route.params.id;
    },
    signatureId () {
      return this.$route.params.signatureId;
    },
    previousRoute () {
      return this.$route.params.previousRoute;
    }
  },
  data () {
    return {
      loading: true,
      error: null
    };
  },
  methods: {
    getEmbeddedSigningURL () {
      let authToken = Cookies.get('auth_token');
      if (!_.isNil(authToken)) {
        this.client.post(
          '/api/v1/hello-sign/get-embedded-sign-url',
          { id: this.signatureId }
        ).then(response => {
          this.mountHelloSignApp(response.data.sign_url, response.data.config)
        }).catch(error => {
          this.error = error.response.data.error;
        })
        .finally(() => {
          this.loading = false;
        })
      } else {
        axios.post(
          '/admin/hello-sign/get-embedded-sign-url',
          { id: this.signatureId }
        ).then(response => {
          this.mountHelloSignApp(response.data.sign_url, response.data.config)
        }).catch(error => {
          this.error = error.response.data.error;
        })
        .finally(() => {
          this.loading = false;
        })
      }
    },
    mountHelloSignApp (embeddedSigningURL, config) {
      const client = new HelloSign({
        allowCancel: true,
        clientId: config.clientId,
        container: document.getElementById('hellosign-container'),
        debug: config.testMode,
        hideHeader: false,
        skipDomainVerification: config.testMode,
        allowViewportOverride: true
      });

      client.on('ready', data => {
        this.loading = false;
      });

      client.on('message', data => {
        if (data.type === HelloSign.messages.USER_CANCEL_REQUEST || data.type === HelloSign.messages.USER_DECLINE_REQUEST) {
          history.back();
        }
        if (data.type === HelloSign.messages.USER_SIGN_REQUEST) {
          this.loading = true;
          axios.post('/sign/' + this.id, {
            signatureId: data.payload.signatureId
          }).then(function (response) {
            window.location = response.data.redirect;
          }).catch(function (error) {
            // do nothing
          });
        }
      });

      client.open(embeddedSigningURL);
    }
  },
  mounted () {
    this.getEmbeddedSigningURL();
  }
}
</script>

<style lang="scss" scoped>
section.sign {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 1320px;
}

#hellosign-container, .hellosign-loading {
  height: 100dvh;
}

.hellosign-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);
}
</style>
