<template>
  <section class="section-narrow">
    <div class="container">
      <PrefilledLoginWarning v-if="prefilled" />
      <div class="alert alert-info" role="alert">
        <i class="fa-du fa-light fa-circle-info me-2"></i>
        If you are a {{ admitteeNomenclature }}, <router-link to="/patient/login" class="alert-link">click here</router-link>.
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Responsible Party</h5>
          <h6 class="card-subtitle mb-2 text-body-secondary">
            Log in as a 3rd-party on behalf of a {{ admitteeNomenclature }}.
          </h6>

          <hr>

          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="first-name"
                  v-model="firstname"
                  :disabled="prefilled"
                  placeholder="First Name"
                >
                <label for="first-name" class="form-label">First Name</label>
              </div>
            </div>
            <div class="col">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="last-name"
                  v-model="lastname"
                  :disabled="prefilled"
                  placeholder="Last Name"
                >
                <label for="last-name" class="form-label">Last Name</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <input
                  type="tel"
                  class="form-control"
                  id="phone"
                  v-model="phone"
                  :disabled="prefilled"
                  placeholder="Phone"
                >
                <label for="phone" class="form-label">Phone</label>
              </div>
            </div>
            <div class="col">
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="email"
                  :disabled="prefilled"
                  placeholder="Email"
                >
                <label for="email" class="form-label">Email</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-floating">
                <input
                  type="password"
                  class="form-control"
                  id="ssn"
                  v-model="last4ssn"
                  maxlength="4"
                  :placeholder="'Last 4 of ' + admitteeNomenclature + ' Social Security Number'"
                >
                <label for="ssn" class="form-label">Last 4 of {{ admitteeNomenclature }} Social Security Number</label>
              </div>
            </div>
          </div>

          <hr>

          <p class="lh-sm text-body-tertiary"><small>
            By using eAdmit, you are agreeing to receive certain information and notices electronically and to sign contracts and other records electronically. For more information on electronic signatures and records, please review our Electronic Signature Terms & Conditions.
          </small></p>

          <button type="submit" class="btn btn-primary float-end" @click="login">
            Next
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Cookies from "js-cookie";
import endpoints from "../../mixins/app/endpoints";
import PrefilledLoginWarning from "./components/PrefilledLoginWarning.vue";
import axios from '@/axios'

export default {
    components: {
        PrefilledLoginWarning
    },
    mixins: [
        endpoints
    ],
    data () {
        return {
            firstname: null,
            lastname: null,
            phone: null,
            email: null,
            last4ssn: null,
            prefilled: false,
        }
    },
    mounted () {
        if (this.$store.getters['store/isAuthenticated']) {
            this.$router.push('/dashboard');
        }

        if (this.$route.query.firstname) {
            this.prefilled = true;
            this.firstname = this.$route.query.firstname;
        }

        if (this.$route.query.lastname) {
            this.lastname = this.$route.query.lastname;
        }

        if (this.$route.query.phone) {
            this.phone = this.$route.query.phone;
        }

        if (this.$route.query.email) {
            this.email = this.$route.query.email;
        }
    },
    methods: {
        login: function () {
            this.$store.dispatch('store/clearErrors');
            axios.post(this.endpoints.signerLogin, {
                name: {
                    firstname: this.firstname,
                    lastname: this.lastname,
                },
                username: {
                    phone: this.phone,
                    email: this.email,
                },
                patient: {
                    last4ssn: this.last4ssn
                }
            }).then(response => {
                Cookies.remove('auth_token');
                Cookies.remove('patient');
                Cookies.remove('signer');

                this.$store.dispatch('store/setSigner', response.data.signer);
                this.$store.dispatch('store/setPatient', response.data.patient);

                Cookies.set('auth_token', response.data.token, { secure: true });

                this.$router.push('/dashboard');
            }).catch(error => {
                this.$store.dispatch('store/setErrors', [ error.response.data.message ]);
            });
        }
    },
    computed: {
        admitteeNomenclature () {
            return window.admitteeNomenclature
        }
    }
}
</script>

<style lang="scss" scoped></style>

